.profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
  font-size: 1.5rem;
  height: calc(calc(100vh - 4rem) - 40px);
  position: relative;
}

.header {
  margin: 0;
}

.info {
  margin-bottom: 0;
  font-size: 1.2rem;
}

.alex {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
  padding-bottom: 8px;
}

.wave {
  animation: wave alternate infinite 1s ease-in-out;
  display: inline-block;
  transform-origin: right bottom;
}

@keyframes wave {
  0% {
    transform: rotate(-20deg);
  }
  100% {
    transform: rotate(20deg);
  }
}

.scrollIndicator {
  position: absolute;
  bottom: 3rem;
  text-align: center;
  font-size: 3rem;
  color: var(--color-orange);
  animation: scrollIndicator infinite 3s ease-in-out alternate;
  cursor: pointer;
}

@keyframes scrollIndicator {
  0% {
    transform: translateY(0);
  }
  80% {
    transform: translateY(0);
  }
  90% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
  }
}
