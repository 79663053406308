@import url("https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@400;800&display=swap");

:root {
  --color-orange: #ff8a00;
  --color-red: #e52e71;
  --color-bg: #0e0d14;
  --color-elevated: #16151c;
}

body {
  background-color: var(--color-bg);
  color: #fff;
  font-family: "JetBrains Mono", monospace;
  font-size: 16px;
  line-height: 1.5;
  margin: 0;
}

.hidden {
  opacity: 0;
  filter: blur(5px);
  translate: -100%;
  transition: translate 0.5s ease calc(50ms * var(--order)),
    transform 0.2s ease 0s, opacity 0.5s ease 0s, filter 0.5s ease 0s !important; /* I used different transition delays for transform and translate properties, meaning when they slide in on view it can be staggered, but when they slide on hover it's instant. */
}

@media (prefers-reduced-motion) {
  .hidden {
    transition: none;
  }
}

.show {
  opacity: 1;
  filter: blur(0);
  translate: 0;
}
